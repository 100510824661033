<template>
  <div class="container">
    <Breadcrumb class="bg-transparent" :breadcrumbList="[
        { 'name': 'Admin Dashboard', 'link': $router.resolve({ name: 'admin' }).href },
        { 'name': 'Gift Cards' }
      ]"/>
    <div class="main-container">
      <h1 class="mb-4 pb-1"><strong>Upload Gift Cards</strong></h1>
      <div class="container">
        <div class="mb-0 content-box">
          <div class="container p-4">
            <div class="row">
              <div class="col-12 col-md-4">
                <strong class="d-block h5">Step 1</strong>
                <p>Download the CSV file and add any new (to add) or existing (to update) gift cards.</p>
                <a href="https://ukelectroneum-my.sharepoint.com/:x:/g/personal/egg_theodorou_electroneum_com/EZIPsE5FYcBAr53vddNJHBMBO_CR7pCFa2C4hyv5jrsI-w?download=1" class="btn btn-primary">Download CSV</a>
              </div>
              <div class="col-12 col-md-4 mt-5 mt-md-0">
                <strong class="d-block h5">Step 2</strong>
                <p>Attach the CSV file below:</p>
                <vue-dropzone ref="myVueDropzone" id="dropzone" class="dropzone-giftcard-upload" :options="dropzoneOptions" :useCustomSlot=true
                              :awss3="awss3"
                              v-on:vdropzone-s3-upload-error="s3UploadError"
                              v-on:vdropzone-s3-upload-success="s3UploadSuccess"
                              v-on:vdropzone-files-added="preFileUpload"
                              v-on:vdropzone-removed-file='dropzoneRemoved'
                >
                  <div class="dropzone-custom-content text-muted">
                    <i class="fa-solid fa-cloud-arrow-up fa-3x"></i>
                    <br/>
                    {{ $t("dropzone_placeholder")}}
                  </div>
                </vue-dropzone>
              </div>
              <div class="col-12 col-md-4 mt-5 mt-md-0">
                <strong class="d-block h5">Step 3</strong>
                <p>Submit the file for processing:</p>
                <b-button v-if="!uploading" class="mt-3 align-content-center" variant="primary" @click="uploadGiftCards" :disabled="s3UploadDisabled">Upload CSV</b-button>
                <b-button v-else class="mt-3 w-50 align-content-center" variant="primary" disabled="disabled">
                  <b-spinner small label="Small Spinner"/>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1 class="mt-5 mb-4 pb-1"><strong>Gift card database</strong></h1>
      <div class="container">
        <div class="col-xl-12 p-4 content-box">
          <p>Request a download of the gift card database. This will be sent to you by email.</p>
          <div class="container">
            <b-button v-if="!exporting" class="align-content-center" variant="primary" @click="downloadGiftCards">
              Request gift card data
            </b-button>
            <b-button v-else class="align-content-center" variant="primary" disabled="disabled">
              <b-spinner small label="Small Spinner"/>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../apiService.js'
import vueDropzone from 'vue2-dropzone'
import { mapActions } from 'vuex'
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'

export default {
  name: 'AdminGiftCard',
  components: {
    Breadcrumb,
    vueDropzone
  },
  data () {
    return {
      file: null,
      ErrorMessage: '',
      ErrorFile: '',
      exporting: false,
      s3UploadDisabled: true,
      uploading: false,
      loadDropzone: false,
      loadingDropzone: false,
      requestRefreshToken: true,
      fileUploading: false,
      ErrorMessageSwitch: false,
      awss3: {
        signingURL: ApiService.getTokenUrl('user'),
        headers: {},
        sendFileToServer: false
      },
      dropzoneOptions: {
        paramName: 'file',
        thumbnailWidth: 100,
        thumbnailHeight: 100,
        maxFilesize: 1,
        maxFiles: 1,
        acceptedFiles: '.csv',
        autoProcessQueue: true,
        timeout: 0,
        retryChunksLimit: 1,
        addRemoveLinks: true,
        dictCancelUpload: this.$t('cancel'),
        dictRemoveFile: this.$t('delete'),
        renameFile (file) {
          // eslint-disable-next-line no-return-assign
          return new Date().valueOf() + '_' + file.name.replace(/[^0-9a-z._-]/gi, '')
        }
      }
    }
  },
  mounted () {
    this.updateToken()
  },
  methods: {
    uploadGiftCards () {
      this.uploading = true
      if (this.file !== null) {
        let data = {
          path: this.file
        }
        ApiService.uploadGiftCards(data).then(response => {
          this.uploading = false
          this.$swal({
            title: 'Success',
            text: 'Gift cards have successfully uploaded and you will get an email when the process has finished.',
            type: 'success',
            confirmButtonText: 'Close'
          }).then(() => {
            this.$refs.myVueDropzone.removeAllFiles()
            this.s3UploadDisabled = true
            this.file = null
          })
        }).catch((error) => {
          let errors = error.response.data.errors.children
          if (errors.message.errors.length > 0) {
            this.ErrorMessage = errors.message.errors[0]
          }
          if (errors.file.errors.length > 0) {
            this.ErrorFile = errors.file.errors[0]
          }
          this.uploading = false
        })
      }
    },

    downloadGiftCards () {
      this.exporting = true
      ApiService.downloadGiftCards().then(response => {
        this.exporting = false

        // sweet alert modal
        this.$swal({
          title: 'Request successful!',
          html: 'Your request is processing and will be <strong>sent to you via email when it has completed.</strong>',
          type: 'success'
        })
      }).catch(() => {
        this.exporting = false
      })
    },

    updateToken () {
      this.fetchNewAccessToken().then(() => {
        this.awss3.headers = {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        }
        this.loadDropzone = true
        this.requestRefreshToken = false
      })
    },
    ...mapActions([
      'fetchNewAccessToken'
    ]),
    showDropzone () {
      if (!this.loadDropzone) {
        if (this.requestRefreshToken) {
          this.loadingDropzone = true
        } else {
          this.loadDropzone = true
        }
      } else {
        this.loadDropzone = false
      }
    },
    s3UploadError (errorMessage) {
      this.fileUploading = false
      this.errorMessageSwitch = true
      this.ErrorFile = errorMessage
    },
    s3UploadSuccess (s3ObjectLocation) {
      this.s3UploadDisabled = false
      this.fileUploading = false
      this.file = s3ObjectLocation
    },
    preFileUpload () {
      this.fileUploading = true
    },
    dropzoneRemoved () {
      this.file = null
    }
  }
}
</script>

<style scoped>
  .dropzone-giftcard-upload .dz-message {
    margin:0 !important;
  }
</style>
